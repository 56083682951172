var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "id": `${_vm.id}-monaco-editor-content`
    }
  }, [_vm.showHeader ? _c('div', {
    class: {
      'header-dark': _vm.themeDark,
      'monaco-header': true
    }
  }, [_c('div', {
    class: {
      'title-dark': _vm.themeDark,
      title: true
    }
  }, [_vm._v(" " + _vm._s(_vm.showTitle ? _vm.title : "") + " ")]), _c('div', {
    staticClass: "icons"
  }, [_vm.showUndo ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('Icon', {
    staticClass: "icon",
    attrs: {
      "type": "ios-undo"
    }
  }), _vm._v(" 撤销 ")], 1) : _vm._e(), _vm.showRedo ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.redo
    }
  }, [_c('Icon', {
    staticClass: "icon l",
    attrs: {
      "type": "ios-redo"
    }
  }), _vm._v(" 恢复 ")], 1) : _vm._e(), _vm.showCopy ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.copy
    }
  }, [_c('Icon', {
    staticClass: "icon l",
    attrs: {
      "type": "md-copy"
    }
  }), _vm._v(" 复制 ")], 1) : _vm._e(), _vm.showDownload ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.download
    }
  }, [_c('Icon', {
    staticClass: "icon l",
    attrs: {
      "type": "md-download"
    }
  }), _vm._v(" 下载 ")], 1) : _vm._e(), _vm.showFormat ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.format
    }
  }, [_c('Icon', {
    staticClass: "icon l",
    attrs: {
      "type": "md-color-palette"
    }
  }), _vm._v(" 美化排版 ")], 1) : _vm._e(), _vm.showSearch ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      btn: true
    },
    on: {
      "click": _vm.find
    }
  }, [_c('Icon', {
    staticClass: "icon l",
    attrs: {
      "type": "ios-search"
    }
  }), _vm._v(" 搜索 ")], 1) : _vm._e(), _vm.showFullscreen ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      'btn l': true
    },
    on: {
      "click": _vm.fullscreen
    }
  }, [_c('Icon', {
    staticClass: "icon",
    attrs: {
      "type": _vm.openFull ? 'md-contract' : 'md-expand'
    }
  }), _vm._v(" " + _vm._s(_vm.openFull ? "退出全屏" : "全屏") + " ")], 1) : _vm._e(), _vm.showTheme ? _c('div', {
    class: {
      'btn-dark': _vm.themeDark,
      'btn l': true
    },
    on: {
      "click": _vm.changeTheme
    }
  }, [_c('Icon', {
    staticClass: "icon",
    attrs: {
      "type": _vm.themeDark ? 'md-sunny' : 'md-moon'
    }
  }), _vm._v(" " + _vm._s(_vm.themeDark ? "明亮主题" : "暗黑主题") + " ")], 1) : _vm._e()])]) : _vm._e(), _c('div', {
    style: {
      overflow: 'hidden',
      height: _vm.realHeight
    },
    attrs: {
      "id": _vm.id
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };