// import * as monaco from "monaco-editor";
import FileSaver from "file-saver";
export default {
  name: "monaco",
  props: {
    id: {
      type: String,
      default: "monaco"
    },
    height: {
      type: [Number, String],
      default: "500px"
    },
    value: {
      type: String,
      default: ""
    },
    language: String,
    lineNumbers: {
      type: String,
      default: "on"
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    cursorStyle: {
      type: String,
      default: "line"
    },
    fontSize: {
      type: Number,
      default: 14
    },
    contextmenu: {
      type: Boolean,
      default: true
    },
    defaultTheme: {
      type: String,
      default: "vs"
    },
    autoFormat: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showUndo: {
      type: Boolean,
      default: true
    },
    showRedo: {
      type: Boolean,
      default: true
    },
    showCopy: {
      type: Boolean,
      default: true
    },
    showDownload: {
      type: Boolean,
      default: true
    },
    downloadSuffix: {
      type: String,
      default: ".txt"
    },
    showFormat: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showFullscreen: {
      type: Boolean,
      default: true
    },
    showTheme: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: this.value,
      monacoEditor: null,
      themeDark: this.defaultTheme == "vs-dark" ? true : false,
      theme: this.defaultTheme,
      realHeight: this.height,
      openFull: false
    };
  },
  methods: {
    init() {
      this.monacoEditor = monaco.editor.create(document.getElementById(this.id), {
        value: this.value,
        language: this.language,
        theme: this.theme,
        // 主题'vs' (default), 'vs-dark', 'hc-black'
        lineNumbers: this.lineNumbers,
        // 显示行号
        readOnly: this.readOnly,
        // 是否只读
        cursorStyle: this.cursorStyle,
        // 光标
        fontSize: this.fontSize,
        // 字体大小
        contextmenu: this.contextmenu,
        // 右键菜单
        formatOnPaste: true,
        formatOnType: true
      });
      // 格式化代码
      if (this.autoFormat) {
        setTimeout(() => {
          if (!this.monacoEditor) {
            return;
          }
          this.monacoEditor.getAction(["editor.action.formatDocument"]).run();
        }, 200);
      }
      // 监听事件
      this.monacoEditor.onDidChangeModelContent(e => {
        let v = this.monacoEditor.getValue();
        this.data = v;
        this.$emit("input", this.data);
        this.$emit("on-change", this.data);
      });
    },
    changeTheme() {
      this.themeDark = !this.themeDark;
      let theme = "vs";
      if (this.themeDark) {
        theme = "vs-dark";
      }
      this.monacoEditor.updateOptions({
        theme: theme
      });
    },
    setData(value) {
      if (!this.monacoEditor) {
        this.init();
      }
      if (value != this.data) {
        this.data = value;
        this.monacoEditor.setValue(this.data);
        // 格式化代码
        if (this.autoFormat) {
          this.monacoEditor.getAction(["editor.action.formatDocument"])._run();
        }
        this.$emit("input", this.data);
        this.$emit("on-change", this.data);
      }
    },
    focus() {
      this.monacoEditor.focus();
    },
    layout() {
      this.realHeight = this.height;
      setTimeout(() => {
        this.monacoEditor.layout();
      }, 10);
    },
    undo() {
      var _this$monacoEditor$ge;
      (_this$monacoEditor$ge = this.monacoEditor.getModel()) === null || _this$monacoEditor$ge === void 0 ? void 0 : _this$monacoEditor$ge.undo();
    },
    redo() {
      var _this$monacoEditor$ge2;
      (_this$monacoEditor$ge2 = this.monacoEditor.getModel()) === null || _this$monacoEditor$ge2 === void 0 ? void 0 : _this$monacoEditor$ge2.redo();
    },
    copy() {
      this.$copyText(this.data).then(e => {
        this.$Message.success("复制成功");
      });
    },
    download() {
      var blob = new Blob([this.data], {
        type: "text/plain;charset=utf-8"
      });
      let title = this.title;
      if (!title) {
        title = "文件";
      }
      title += this.downloadSuffix;
      FileSaver.saveAs(blob, title);
    },
    format() {
      this.monacoEditor.getAction(["editor.action.formatDocument"])._run();
    },
    find() {
      this.monacoEditor.getAction(["actions.find"])._run();
    },
    fullscreen() {
      let main = document.getElementById(`${this.id}-monaco-editor-content`);
      this.openFull = !this.openFull;
      if (this.openFull) {
        this.realHeight = Number(document.body.clientHeight - (this.showHeader ? 40 : 0)) + "px";
        main.classList.add("editor-fullscreen");
        setTimeout(() => {
          this.monacoEditor.layout();
        }, 10);
      } else {
        this.realHeight = this.height;
        main.classList.remove("editor-fullscreen");
        setTimeout(() => {
          this.monacoEditor.layout();
        }, 10);
      }
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    if (this.monacoEditor != null) {
      this.monacoEditor.dispose();
    }
  },
  watch: {
    value(val) {
      this.setData(val);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  }
};